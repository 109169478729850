import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UseMutationOptions, UseQueryOptions } from 'react-query/types/react/types';
import { ITemplateVariable, IResponseTemplate } from '@tymely/atoms';
import {
    fetchResponseTemplate,
    getTemplateFreeVariables,
    renderTemplate,
    editResponseTemplate,
    createResponseTemplate,
    TemplateError
} from '@tymely/api';
import { useSetAlert } from './alerts.services';
import { AxiosError } from 'axios';

export const useFetchResponseQuery = (templateId?: number, options?: { onSuccess?: (template: IResponseTemplate) => void, initialData?: IResponseTemplate }) => {
    const setAlert = useSetAlert();
    return useQuery<IResponseTemplate>(
        ['response-template', templateId],
        () => fetchResponseTemplate(templateId!),
        {
            onError: () => {
                setAlert(`Failed to load template ${templateId}`, 'error');
            },
            enabled: Boolean(templateId),
            ...options,
        }
    );
};

export const useRenderTemplateQuery = (
    template: string,
    variables: Record<string, ITemplateVariable>,
    options?: UseQueryOptions<string>
) =>
    useQuery<string>(
        ['rendered-template', template, variables],
        () => renderTemplate(template, variables),
        {...options, retry: false}
    );

export const useTemplateFreeVariablesQuery = (template: string, options?: UseQueryOptions<string[]>) =>
    useQuery<string[]>(
        ['free-vars', template],
        () => getTemplateFreeVariables(template),
        {...options, retry: false}
    );

export const useEditResponseTemplateMutation = (options?: UseMutationOptions<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>) => {
    const queryClient = useQueryClient();

    return useMutation<IResponseTemplate, AxiosError | TemplateError, IResponseTemplate>(
        editResponseTemplate,
        {
            onSuccess: (data) => {
                queryClient.setQueriesData(['response-template', data.id], () => data);
            },
            ...options
        }
    );
};

export const useCreateResponseTemplateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation<IResponseTemplate, AxiosError, Omit<IResponseTemplate, 'id'>>(
        createResponseTemplate,
        {
            onSuccess: (data) => {
                queryClient.setQueriesData(['response-template', data.id], () => data);
            },
        }
    );
};
