/*
    This module is a work in progress, all the commented code will be removed/refactored in following releases.
 */

import { ComponentProps, memo } from 'react'
import { githubLight } from '@uiw/codemirror-theme-github'
import { Diagnostic, linter, lintGutter } from '@codemirror/lint'
import CodeMirror from '@uiw/react-codemirror'
import { CompletionContext, autocompletion } from '@codemirror/autocomplete'
import { langs } from '@uiw/codemirror-extensions-langs'
import { EditorView } from '@codemirror/view'
import { ITemplateError } from '@tymely/atoms'
// import { syntaxTree, StreamLanguage } from '@codemirror/language'
// import { lezer } from '@codemirror/lang-lezer'
// import { json } from '@codemirror/lang-json'
// import {
//     LRLanguage,
//     indentNodeProp,
//     continuedIndent,
//     foldNodeProp,
//     foldInside,
//     LanguageSupport
// } from '@codemirror/language'
// import { buildParser } from '@lezer/generator'
// import { syntax } from './myLang'

function myCompletions(context: CompletionContext) {
    const word = context.matchBefore(/\w*/);
    // console.log(context);
    // console.log(syntaxTree(context.state));

    if (!word || (word.from === word.to && !context.explicit))
        return null;

    return {
        from: word.from,
        options: [
            {label: 'match', type: 'keyword'},
            {label: 'hello', type: 'variable', info: '(World)'},
            {label: 'magic', type: 'text', apply: '⠁⭒*.✩.*⭒⠁', detail: 'macro'}
        ]
    };
}

// const parser = buildParser(syntax)
//
// export const jinjaLanguage = LRLanguage.define({
//     name: 'jinja',
//     parser: parser.configure({
//         props: [
//             indentNodeProp.add({
//                 Object: continuedIndent({except: /^\s*}/}),
//                 Array: continuedIndent({except: /^\s*]/})
//             }),
//             foldNodeProp.add({
//                 'Object Array': foldInside
//             })
//         ]
//     }),
//     languageData: {
//         closeBrackets: {brackets: ['[', '{', '"']},
//         indentOnInput: /^\s*[}\]]$/
//     }
// })
//
// /// JSON language support.
// export function jinja() {
//     return new LanguageSupport(jinjaLanguage)
// }


const jinjaLinter = (errors: ITemplateError[]) => (view: EditorView): Diagnostic[] =>
    errors.map(err => {
        const from = view.state.doc.line(err.location?.line ?? 0).from + (err.location?.char ?? 0);

        return {
            from: from,
            to: from,
            message: err.description,
            severity: 'error'
        }
    })


export const JinjaCodeEditor = memo((props: {
    code: string
    onChange?: (code: string) => void
    height?: ComponentProps<typeof CodeMirror>['height']
    width?: ComponentProps<typeof CodeMirror>['width'],
    errors?: ITemplateError[]
}) => {
    return (
        <CodeMirror
            value={props.code}
            theme={githubLight}
            readOnly={!props.onChange}
            extensions={[
                lintGutter(),
                langs.jinja2(),
                linter(jinjaLinter(props.errors ?? []), {
                    delay: 0,
                    markerFilter: (diags) => diags.filter(diag => diag.from !== diag.to)
                })
                // lezer(),
                // autocompletion({'override': [myCompletions]})
            ]}
            width={props.width}
            height={props.height}
            editable={Boolean(props.onChange)}
            onChange={props.onChange}
            basicSetup={{
                'autocompletion': true,
                'syntaxHighlighting': true,
                'foldGutter': true,
                'tabSize': 4
            }}
        />
    )
});
