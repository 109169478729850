import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
import qs from 'qs';

import { getEnvValue } from '@tymely/config';
import { IIntent, IOrganization, ITemplateError, IWorkflow } from '@tymely/atoms'

export type ErrorCode =
    | 'NO_POLICY'
    | 'STALE_TICKET_UPDATE'
    | 'ARGUMENT_METADATA_IN_USE'
    | 'TEMPLATE_ERROR'
    | 'BAD_SCHEMA';

export class ApiError<C extends ErrorCode, D> extends AxiosError {
    override code: C;
    detail: D;

    constructor(code: C, message: string, detail: D) {
        super(message);
        this.message = message;
        this.code = code;
        this.detail = detail
    }
}

export interface ArgMdUsageDetails {
    organization_id: IOrganization['id']
    intent_id: IIntent['id']
    workflow_id: IWorkflow['id']
}

export interface ArgMdActionUsageDetails extends ArgMdUsageDetails {
    path: string
}

export interface ArgMdInUseDetails {
    policies: ArgMdUsageDetails[]
    actions: ArgMdActionUsageDetails[]
    templates: ArgMdUsageDetails[]
}

export interface ISchemaError {
    detail: {
        msg: string,
        type: string,
        loc: (string | number)[]
    }[]
}

export class ArgumentUsedError extends ApiError<'ARGUMENT_METADATA_IN_USE', ArgMdInUseDetails> {
    constructor(message: string, detail: ArgMdInUseDetails) {
        super('ARGUMENT_METADATA_IN_USE', message, detail)
    }
}

export class NoPolicyError extends ApiError<'NO_POLICY', null> {
    constructor(message: string) {
        super('NO_POLICY', message, null)
    }
}

export class StaleTicketUpdate extends ApiError<'STALE_TICKET_UPDATE', null> {
    constructor(message: string) {
        super('STALE_TICKET_UPDATE', message, null)
    }
}

export class TemplateError extends ApiError<'TEMPLATE_ERROR', ITemplateError[]> {
    constructor(message: string, detail: ITemplateError[]) {
        super('TEMPLATE_ERROR', message, detail)
    }
}

export class SchemaError extends ApiError<'BAD_SCHEMA', ISchemaError> {
    constructor(message: string, detail: ISchemaError) {
        super('BAD_SCHEMA', message, detail)
    }
}

axios.defaults.baseURL = getEnvValue('NX_BASE_URL');

axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, {indices: false});
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 422) {
            throw new SchemaError(
                'Bad schema',
                error.response.data
            )
        }

        if (error.response?.status >= 400 && error.response?.data) {
            switch (error.response.data.code) {
                case 'NO_POLICY':
                    throw new NoPolicyError(
                        error.response.data.detail
                    );
                case 'STALE_TICKET_UPDATE':
                    throw new StaleTicketUpdate(
                        error.response.data.detail
                    );
                case 'ARGUMENT_METADATA_IN_USE':
                    throw new ArgumentUsedError(
                        'Argument metadata is being used',
                        error.response.data.detail
                    );
                case 'TEMPLATE_ERROR':
                    throw new TemplateError(
                        'Template error',
                        error.response.data.detail
                    );
                default:
                    throw new ApiError(
                        error.response.data.code,
                        error.response.data.detail,
                        null
                    );
            }
        }
        throw error;
    }
);

axios.interceptors.request.use(async function (
    config: AxiosRequestConfig<object>
) {
    config.headers = {
        ...config.headers,
        Authorization: await getJwtToken()
    };
    return config;
});

async function getJwtToken() {
    try {
        const currentSession = await Auth.currentSession();
        return currentSession.getIdToken().getJwtToken();
    } catch {
        return Promise.reject(() => window.location.reload());
    }
}
